import anime from "animejs/lib/anime.es.js";

function animateElements(elements) {
  anime({
    targets: elements,
    opacity: 1,
    translateY: [100, 0],
    delay: anime.stagger(100),
    easing: "easeOutQuad",
  });
}

function animateElementsArrow(arrowOne) {
  anime({
    targets: arrowOne,
    opacity: 1,
    translateX: [300, 0],
    delay: anime.stagger(500, { start: 350 }),
    easing: "easeInOutSine",
  });
}

function animateElementsArrowTwo(arrowTwo) {
  anime({
    targets: arrowTwo,
    opacity: 1,
    translateX: [0, 300],
    delay: anime.stagger(500, { start: 350 }),
    easing: "easeInOutSine",
  });
}

function handleIntersection(entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      animateElements(entry.target.querySelectorAll(".box"));
      animateElementsArrow(entry.target.querySelectorAll(".arrow-left"));
      animateElementsArrowTwo(entry.target.querySelectorAll(".arrow-rigth"));
      observer.unobserve(entry.target);
    }
  });
}

const observer = new IntersectionObserver(handleIntersection, {
  root: null,
  rootMargin: "0px",
  threshold: 0.5,
});

document.querySelectorAll(".section-to-animate").forEach((section) => {
  observer.observe(section);
});
